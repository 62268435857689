

.custom-dropdown .dropdown-toggle {
    background-color: #EC2124;
    border: #EC2124; /* Change the color of the dropdown toggle */
    color: rgb(255, 255, 255); /* Change text color for better visibility */
    padding: 3px 10px 3px 10px;
    margin-top: 3px;
    padding-right: 10px;
}
.navbar {
  width: 100%;/* Maximum width of the Navbar */
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
  background-color: white;
  animation: fadeIn 1s ease-in-out;

}
@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

.input-group-sm .form-control {
    height: 37px; /* Adjust height of input */
    max-width: 200px;/* Optional: adjust width for better alignment */
}
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}
input[type="search"]:focus {
    outline: none;
    box-shadow: none;
}

/* CategoryMenu.css */
.category-menu {
  max-width: 1200px; /* Maximum width for the category menu */
  margin: -1px auto; /* Center the category menu */
  display: flex;
  size: 1px !important;
  justify-content: center;
  padding: 10px 15px;
}

.category-item {
  text-align: center;
  margin: 0 15px;
}

.category-icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}
.category-menu {
    display: flex;
    justify-content: space-around;
  }
  
  .category-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #b62a2a;
    text-decoration: none;
  }
  .category-item {
    transition: transform 0.3s, opacity 0.3s;
}

.category-item:hover {
    transform: scale(1.1);
    opacity: 1 !important; /* Ensure full opacity on hover */
}

  
  .category-icon {
    width: 30px;
    height: 30px;
    margin-bottom: 0px;
  }
  
  .category-item {
     /* Change to your preferred color */
    transition: color 0.3s; /* Smooth transition for color */
}

.category-item:hover {
    color: darkred; /* Change color on hover */
}
