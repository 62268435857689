.footer {
    background-color: #f8f9fa;
    padding: 40px 0;
    color: #333;
  }
  .footer:hover{
    color: #000;
  }
  
  .logo-section {
    display: flex;
    align-items: center;
  }
  
  .logo-img {
    height: 75px;
    position: relative;
    right: 60px;
    
  }
  
  .footer-logo-title {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
  }
  
  .social-icons a {
    color: #333;
    margin-right: 10px;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    transition: color 0.3s ease;
  }
  
  .social-icons a.facebook:hover{
    color: #3b5998;
  }

  .social-icons a.instagram:hover{
    color: #ee2a7b;
  }
  
  .social-icons a.twitter:hover{
    color: #1da1f2;
  }

  .social-icons a.youtube:hover{
    color: #ff0000;
  }

  .footer-nav li {
    list-style: none;
    margin-bottom: 10px;
  }
  
  .footer-nav a {
    color: #EC2124;
    text-decoration: none;
    transition: color 0.3s;
    font-family: Alice;
    font-weight: bold;
  }
  
  .footer-nav a:hover {
    color: black;
    font-weight: bold;
  }
  
  .footer-store {
    font-size: 10px;
    margin-bottom: 20px;
  }
  
  .footer-contact, .footer-timings {
    font-size: 16px;
    font-weight: bold;
    margin-top: 20px;
    color: #EC2124;
  }
  
  
  .footer p a {
    color: #333;
    text-decoration: none;
    transition: color 0.3s;
  }
  

  
  @media (max-width: 767px) {
    .footer-store, .footer-contact, .footer-timings, .footer-nav {
      text-align: center;
    }
  }
  .footer-section-title{
      width: 400px;
      color: #333;
      position: relative;
      bottom: 10px;
      font-weight: bold;
  }

  .footer-store{
    font-size: 10px;
  }

  li{
    margin-bottom: 0px !important;
    font-size: 13px;
  }
  .social-icons{
    margin-right: 22px;
  }

   .text-md-left{
        margin-bottom: 10px;
   }
   .footer-store {
    margin-bottom: 15px; /* Add space between store entries */
    line-height: 1.5; /* Increase line height for readability */
  }
  
  /* Optional: Adjust title styling */
  .footer-section-title {
    margin-bottom: 10px; /* Space below the title */
    font-weight: bold; /* Make the title bold */
    color: #EC2124;
  }
  
  