.offcanvas-header {
  background-color: #f8f9fa;
  font-weight: bold;
}

.cart-header {
  background-color: #EC2124;
  text-align: center;
  color: white;
  padding: 10px 0;
  margin-bottom: 15px;
}

.congrats {
  background-color: #fff9f9;
  color: #000000;
  font-size: 14px;
  margin-bottom: 10px;
  padding-left: 80px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dotted #ddd;
}

.item-details p {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.quantity-controls {
  display: flex;
  align-items: center;
}

.quantity-btn {
  border: 1px solid #ddd;
  background-color: white;
  padding: 0 10px;
  cursor: pointer;
}

.quantity-input {
  width: 40px;
  text-align: center;
  border: 1px solid #ddd;
}

.cart-pricing {
  text-align: right;
}

.item-weight, .item-price {
  display: block;
  font-size: 14px;
  font-weight: bold;
}

.original-price {
  text-decoration: line-through;
  color: #999;
  font-size: 12px;
}

.bill-details {
  padding: 10px 0;
}

.bill-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 14px;
}

.total-row {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
}

.total-price {
  color: #EC2124;
}

.checkout-btn {
  width: 100%;
  background-color: #EC2124;
  border: none;
  padding: 10px;
  font-size: 16px;
  margin-top: 20px;
}
