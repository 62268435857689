.banner-container {
    position: relative;
    background-color: #EC2124; /* Background color */
    color: #ffffff; /* Text color */
    padding: 13px;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
}

.banner {
    transition: transform 0.2s ease-in-out; /* Smooth transition */
    opacity: 0;
    position: absolute; /* Position absolute for smooth transition */
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(1); /* Center the text */
}

.banner.visible {
    opacity: 1; /* Make it visible */
    transform: translate(-50%, -50%) scale(1.1); /* Zoom in effect */
}

.banner a {
    color: white;
    text-decoration: none;
}
