.otp-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.otp-modal-logo {
  width: 300px;
  margin-left: 50px;
}

.close-button {
  color: #000;
  
}

.otp-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.otp-message {
  text-align: center;
  color: #6c757d;
}
