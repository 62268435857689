.product-gallery {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    text-align: center;
    padding: 100px 20px 100px 20px;
    font-family: Arial, sans-serif;
}

.product-item {
    flex: 1;
    margin: 0 20px;
}

.product-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.product-title {
    color: #A00000;
    font-size: 1.5em;
    margin: 10px 0;
}

.product-description {
    font-size: 1em;
    color: #000;
}
