.why-freshiouz {
    padding: 2rem;
    border-radius: 8px;
  }
  
  .why-title {
    font-size: 2rem;
    color: #ec2124;
    font-weight: bold;
    text-align: center;
  }
  
  .why-intro {
    font-size: 1.1rem;
    color: #333;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .why-feature h5 {
    font-size: 1.2rem;
    color: #ec2124;
    margin-top: 1.5rem;
    font-weight: bold;
  }
  
  .why-feature p {
    font-size: 1rem;
    color: #555;
    margin-top: 0.5rem;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .why-title {
      font-size: 1.5rem;
    }
  
    .why-feature h5 {
      font-size: 1.1rem;
    }
  
    .why-feature p {
      font-size: 0.9rem;
    }
  }
  