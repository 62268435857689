.info-section {
    padding-top: 30px !important;
  }
  
  .info-icon img {
    max-width: 60px; /* Adjust size as needed */
    margin-bottom: 1rem;
  }
  
  .text-center h4 {
    font-size: 20px !important; 
  }
  
  .text-center p {
    color: #6c757d;
  }
  @import url('https://fonts.googleapis.com/css2?family=Alice&display=swap');

.head {
  font-family: 'Alice', serif;
  font-weight: bold;
  
  font-size: 15px !important;
margin-bottom: 0px !important;
}

.info-section p {
  font-family: 'Alice', serif;
  font-size: 12px;
  font-weight: bold;

}